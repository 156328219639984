
































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Checkbox extends Vue {
  value = false
  @Prop({ default: false }) fillWidth: boolean | undefined
  @Prop({ default: false }) isTransparentBg: boolean | undefined
  @Prop({ default: false }) isPa6: boolean | undefined
}
