import { render, staticRenderFns } from "./checkbox.vue?vue&type=template&id=76b821dc&scoped=true&"
import script from "./checkbox.vue?vue&type=script&lang=ts&"
export * from "./checkbox.vue?vue&type=script&lang=ts&"
import style0 from "./checkbox.vue?vue&type=style&index=0&id=76b821dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76b821dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})
