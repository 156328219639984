var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center"},[_c('div',{class:{
      'pa-6': _vm.isPa6,
      'px-5 py-3': !_vm.isPa6,
      active: _vm.value,
      'has-error': _vm.$attrs.error,
      'fill-width': _vm.fillWidth,
      box: !_vm.isTransparentBg,
      'box-transparent': _vm.isTransparentBg,
    }},[_c('v-checkbox',_vm._b({attrs:{"off-icon":"mdi-checkbox-blank-circle-outline","on-icon":"mdi-check-circle","hide-details":""},on:{"change":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:{ 'active-color': _vm.value, 'default-color': !_vm.value }},[_vm._v(_vm._s(_vm.$attrs.label))])]},proxy:true}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'v-checkbox',_vm.$attrs,false))],1),_c('div',{staticClass:"error-message d-flex justify-end"},[_vm._v(" "+_vm._s(_vm.$attrs['error-message'] ? _vm.$attrs['error-message'].join(', ') : '')+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }